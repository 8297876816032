import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from "../components/common"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import ContactUsBtn from "../components/common/ContactUsBtn";

let videoBG = require('../images/page-images/RoofInspection.jpg')

class BridgeAndInfrastructure extends Component {
    render() {
        return (
            <Layout>

                <div className="container">
                    <h1>
                        Roof & Building Inspection
                    </h1>
                    <div className="row">
                        <div className="col-md-6">
                            <img className={'mbl'} src={videoBG} alt=""/>
                            <p>
                                A drone building survey can help identify and locate imperfections or damage to a building. Drone roofing surveys can include high-resolution images revealing defects to gutters, parapets, skylights and more. Aerial inspection with drones also reduces the disruption to regular business workflow as well as the dangers associated with working at height. Aerial inspection of buildings and roofs with drones is usually completed much quicker than a traditional inspection.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <h4>Safer, Quicker, Cheaper Building & Roof Inspection</h4>
                            <p>
                                Drones can fly near buildings and get a view of difficult locations where other means of access may not be practical, cost-effective or safe. A drone can provide detailed videos and photos of roofs and buildings. Hard to access areas can be seen up close without having to use additional personnel or equipment. Infrared photography can help identify costly heating and cooling leaks.
                            </p>

                            <ul>
                                <li>Large commercial buildings</li>
                                <li>Steep residential roofs</li>
                                <li>Highrise buildings</li>
                            </ul>

                            <ContactUsBtn/>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

BridgeAndInfrastructure.propTypes = {}

export default BridgeAndInfrastructure
